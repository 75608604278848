<template>
    <Transition name="fade">
        <tr>
            <td>{{ rowValue.object }}</td>
            <td>{{ getTranslationByKey(`ems.source_type.${rowValue.source}`) }}</td>
            <td style="min-width: 180px;">
              <div style="white-space: nowrap;">{{ utcDateToLocalDate(rowValue.from) }}</div>
              <div style="white-space: nowrap;">{{ utcDateToLocalDate(rowValue.to)  }}</div>
            </td>
            <td>{{ rowValue.duration }}</td>
            <td>{{ rowValue.loadedSize }}</td>
            <td>{{ rowValue.size }}</td>
            <td>{{ utcDateToLocalDate(rowValue.requestedAt) }}</td>

          <td>
            <div v-if="rowValue.statusInfo" style="white-space: nowrap;">
              <span>
                {{
                  getTranslationByKey(
                      (rowValue.statusInfo === 'next_try_after' && isError && isInRetry)
                          ? 'next_try_waiting'
                          : rowValue.statusInfo
                  ).replace(':count', rowValue.orderInQueue)
                }}
              </span>
              <span v-if="isError && !isInRetry">
                {{ utcDateToLocalDate(rowValue.nextTryAt) }}
              </span>
            </div>
            <div class="object-info-status" v-if="!isCompleted && rowValue.objectInfo">
              {{ getTranslationByKey(rowValue.objectInfo) }}
            </div>
          </td>

            <td class="td-progress-container">
                <div v-if="!isCompleted && !isError">
                    <div class="progress td-element" 
                        @mouseover="showCancelButton = true"
                        v-if="!showCancelButton"
                    >
                        <div class="progress-bar progress-bar-striped progress-bar-animated" 
                            role="progressbar" 
                            :aria-valuenow="rowValue.percent" 
                            aria-valuemin="0" 
                            aria-valuemax="100" 
                            :style="`width: ${rowValue.percent}%`"
                        ></div>
                        <span 
                            class="progress-inner" 
                            :class="rowValue.percent > 50 ? 'text-white': ''" 
                        >
                            {{ rowValue.percent  }}%
                        </span>
                    </div>
                    <button
                        @mouseleave="showCancelButton = false" 
                        @click="cancelVideo"
                        class="btn btn-sm btn-danger td-element d-flex align-items-center justify-content-center" 
                        v-else
                    >
                        <i v-if="cancelLoader" class="fa fa-spinner fa-spin"></i>
                        <span v-else>{{ getTranslationByKey('ems.action.close') }}</span>
                    </button>
                </div>

                <div v-if="isError" class="error d-flex gap">
                  <button
                      @mouseleave="showCancelButton = false"
                      class="btn btn-sm btn-info td-element d-flex align-items-center justify-content-center"
                      @click="retryVideo"
                      :disabled="isInRetry"
                  >
                    <i v-if="isInRetry" class="fa fa-spinner fa-spin"></i>
                    <span v-else>{{ getTranslationByKey('ems.action.retry') }}</span>
                  </button>
                  <button
                      @mouseleave="showCancelButton = false"
                      @click="cancelVideo"
                      class="btn btn-sm btn-danger td-element d-flex align-items-center justify-content-center"
                  >
                    <i v-if="cancelLoader" class="fa fa-spinner fa-spin"></i>
                    <span v-else>{{ getTranslationByKey('ems.action.close') }}</span>
                  </button>
                </div>
                
                <div v-if="isCompleted && rowValue.statusName !== 'error'">
                    <DownloadButton
                        :rowValue="rowValue"
                        :class="!allowDownload ? 'disabled' : ''"
                    />
                </div>
            </td>
        </tr>
    </Transition>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import helper from '../../helper';
import api from '../../api';
import DownloadButton from "./DownloadButton.vue";
    export default {
      components: {DownloadButton},
        props: {
            rowValue: {
                type: Object
            },
            allowDownload: {
              type: Boolean
            }
        },
        data() {
            return {
                showCancelButton: false,
                cancelLoader: false,
                retryLoader: false,
                downloadLoader: false,
            }
        },
        computed: {
            ...mapGetters([
                'getTranslationByKey', 'user'
            ]),
            isCompleted() {
                return this.rowValue.statusName === 'complete';
            },
            isError() {
                return this.rowValue.statusName === 'error';
            },
            isInRetry() {
              const formattedDateString = this.rowValue.nextTryAt.replace(' ', 'T');
              return new Date(formattedDateString) < new Date();
            },
            // showDownloadButton() {
            //   return this.user && 
            //   this.user.permission['sm.ems.library.video.view.my'] && 
            //   this.user.permission['sm.ems.library.video.download']
            // }
        },
        methods: {
            ...mapMutations(["REMOVE_CANCELED_VIDEO"]),
            utcDateToLocalDate(datetime) {
                return helper.utcDateToLocal(datetime)
            },
            async cancelVideo() {
                this.cancelLoader = true;
                try {
                    const response = await api.post(`ems/evidence-request/${this.rowValue.id}/cancel`);

                    if (response.status == 200) {
                        setTimeout(() => {
                            this.REMOVE_CANCELED_VIDEO(this.rowValue.id);
                            this.showCancelButton = false;
                        }, 200)
                    }
                    
                } catch (error) {
                    this.handleErrorMixin(error);
                }
            },
            async retryVideo() {
              this.retryLoader = true;
              try {
                const response = await api.post(`ems/evidence-request/${this.rowValue.id}/retry`);

                if (response.status == 200) {
                  setTimeout(() => {
                    console.log(response.data);
                    if (response.data?.next_try_at) {
                      this.rowValue.nextTryAt = response.data.next_try_at;
                    }
                    if (response.data?.size) {
                      this.rowValue.size = response.data.size;
                    }
                    if (response.data?.loadedSize) {
                      this.rowValue.loadedSize = response.data.loaded_size;
                    }
                  }, 200)
                }

              } catch (error) {
                this.handleErrorMixin(error);
              }
            },
        }
    }
</script>

<style lang="scss" scoped>
.gap {
  gap: 5px;
}
table .disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: gray;
  color: black;
  border: inherit;
  opacity: .4
}
tr:hover {
    background: #f5fdff; //#eef7ff
}
td {
    vertical-align: middle;
}
.td-progress-container {
    position: relative;
    width: 120px;
    .td-element {
        width: 100%;
        height: 21px;
        font-size: 10px;   
    }
    .error {
        font-size: 15px;
        text-align: center;
        color: red;
        letter-spacing: 1px;
    }
}
.progress-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
}
.object-info-status {
  font-size: 70%;
  opacity: 0.7;
}
</style>