<template>
  <div class="app-main">
    <div v-show="preloader" class="search-preload">
      <img
        src="/img/smart_systems_logo.png"
        alt=""
        class="logo-intro mb-1 d-md-block "
      >

      <div class="floating-container">
        <div class="dot-floating"></div>
      </div>
    </div>

    <div v-if="transLength">
      <app-navbar v-if="$route.meta.authorize"></app-navbar>
      <vue-draggable-resizable 
        v-for="one in streamPIP" :key="one.id"
        :w="400" 
        :h="225" 
        :min-width="400"
        :min-height="255"
        class-name="custom-drag" 
        :x="one.x"
        :y="one.y"
        :z="1059"
        :enable-native-drag="true"
        :handles="['br']"
        :active="true"
        :prevent-deactivation="true"
      > 
        <PIP :pip="one"></PIP>
      </vue-draggable-resizable>
      <WSOnlineOfflineConnection v-if="showOnlineOfflineConnectionComponent" />
      <div :class="handleScrollWrapperClass">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
      <warning-alarm
          v-if="showSOSAlarm"
          :alarm-data="SOSData"
          @modalClosed="showSOSAlarm = !showSOSAlarm"
      ></warning-alarm>
      <SessionKill v-if="getShowSessionPopup" />
    </div>
  </div>
</template>

<script>
 import './assets/scss/preloader.css';
 import VueDraggableResizable from 'vue-draggable-resizable'
 import PIP from './components/object/PIP.vue'
import AppNavbar from "./layouts/AppNavbar.vue";
import WarningAlarm from "./components/global/WarningAlarm.vue";
import SessionKill from './components/global/SessionKill.vue';
import WSOnlineOfflineConnection from "./components/global/WSOnlineOfflineConnection.vue";
import {mapActions, mapGetters} from "vuex";
export default {
  components: {
    AppNavbar,
    WarningAlarm,
    WSOnlineOfflineConnection,
    VueDraggableResizable,
    PIP,
    SessionKill
  },
  data() {
    return {
      preloader: true,
      showSOSAlarm: false,
      SOSData: {},
      offline: null,
    };
  },
  computed: {
    ...mapGetters([
      "config", 
      "settings", 
      "translations", 
      "getTranslationByKey",
      "streamPIP",
      "map",
      "filteredVehicles",
      "all",
      "user",
      "getShowSessionPopup"
    ]),
    trans() {
      return this.translations;
    },
    transLength() {
      return this.trans && Object.keys(this.trans).length;
    },
    sessionLifetime() {
      return this.settings.session_lifetime;
    },
    objectsList() {
      return this.filteredVehicles || this.all;
    },
    handleScrollWrapperClass() {
      return this.$route.name !== 'objects' && this.$route.name != 'login' ? 'scroll-wrapper': '';
    },
    showOnlineOfflineConnectionComponent() {
      return this.$route.name !== 'login' && this.$route.name !== 'forgot-password'
    }
  },

  async created() {
    const lang = localStorage.getItem("selectedLanguage");
    const user = JSON.parse(localStorage.getItem('user'));

    await this.getConfig();
    await this.getSettings();

    this.preloader = false;

    if (user && user.auth) {
      this.setUser(user)
      await this.checkUser();
      await this.watchMapAndLoadObjects()
      
    } else {
      await this.watchMapAndLoadObjects()
    }

    await this.getTranslations(lang || this.settings.bo_language_default);

  },
  mounted() {
    this.$watch('objectsList',async newVal => {
      this.SOSAlarm()
    });
  },
  methods: {
    ...mapActions([
      "getConfig", 
      "getSettings", 
      "getTranslations", 
      "setUser", 
      "checkUser",
      "getVehicles",
      "addInterval",
    ]),
    SOSAlarm() {
      let chanel = this.$pusher.subscribe(`private-sos.${this.user.uid}`)
      chanel.bind('unit', data => {
        this.SOSData = data.unit;
        this.showSOSAlarm = true;
      });
    },
    watchMapAndLoadObjects() {
      this.unwatchMap = this.$watch('map', async (newVal) => {
        if (newVal !== null) {
          await this.getVehicles();
          this.unwatchMap();
        }
      });
    },
  },
};
</script>