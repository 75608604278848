<template>
    <div class="pip-container" 
        @mouseover="showBtn = true" 
        @mouseleave="showBtn = false"
    >
        <video :ref="pip.id" class="shadow"></video>
        <div 
            class="pip-controls" 
        >
            <span>{{ pip.title }}</span>
            <div class="volume-controls"
                 :class="{'show-volume-controls': showBtn}">
                <input
                    type="range"
                    min="0"
                    max="1"
                    step="0.1"
                    v-model="volume"
                    @input="setVolume"
                    @mousedown.stop
                    @touchstart.stop
                />
                <i
                    class="fas"
                    :class="muted ? 'fa-volume-mute' : 'fa-volume-up'"
                    @click="toggleMute"
                ></i>
            </div>
            <i class="far fa-share-square fa-sm return-to-original-icon"
                :class="{'show-back-btn': showBtn}"
                @click="goBackToOriginal(pip.id)"
            ></i>
        </div>
    </div>
</template>

<script>
import Hls from 'hls.js'
import {mapGetters, mapMutations} from 'vuex'
import { EventBus } from '../../bus'
import Swal from "sweetalert2";

export default {
    props: {
        pip: Object
    },
    data() {
        return {
            showBtn: false,
            hls: null,
            muted: false,
            volume: 1
        }
    },
    mounted() {
        EventBus.$emit('pip-open', this.pip.id);
        let video = this.$refs[this.pip.id]
        this.hls = new Hls();
        this.hls.loadSource(this.pip.src);
        this.hls.attachMedia(video);

        this.muted = this.pip.muted;
        this.volume = this.pip.volume;
        video.poster = this.pip.poster;
        video.currentTime = this.pip.time + 3; //3 - Seconds reserve that could be spent on buffer loading

        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
            video.play()
        })

        video.muted = this.muted;
        video.volume = this.volume;
    },
    computed: {
        ...mapGetters(["getTranslationByKey"]),
    },
    watch: {
        muted(newValue) {
            const video = this.$refs[this.pip.id];
            video.muted = newValue;
        }
    },
    methods: {
        ...mapMutations(['removeSinglePIP']),
        goBackToOriginal(id) {
            this.hls.destroy()
            this.hls.off(Hls.Events.MEDIA_ATTACHED);
            this.hls.off(Hls.Events.MANIFEST_PARSED);
            this.hls.off(Hls.Events.LEVEL_LOADED);
            this.hls.off(Hls.Events.LEVEL_UPDATED);
            this.hls.off(Hls.Events.ERROR);

            this.removeSinglePIP(id)
            console.log('close', this.muted)
            EventBus.$emit('pip-close', this.pip.id, this.muted, this.volume);
        },
        toggleMute() {
            if (this.muted === true) {
                Swal.fire({
                    title: this.getTranslationByKey('vehicles.stream.requesting_audio'),
                    text: this.pip.title,
                    position: 'top',
                    icon: 'question',
                    showConfirmButton: true,
                    showCancelButton: true,
                    width: '25rem',
                    confirmButtonColor: 'green',
                    confirmButtonText: this.getTranslationByKey('vehicles.stream.ok'),
                    cancelButtonText: this.getTranslationByKey('vehicles.stream.close'),
                    customClass: {
                        confirmButton: 'btn',
                        cancelButton: 'btn',
                        icon: 'swal-custom-icon'
                    }
                }).then(async ({ isConfirmed }) => {
                    if (isConfirmed) {
                        this.muted = false;
                    }
                })
            } else {
                this.muted = true;
            }
        },
        setVolume() {
            let video = this.$refs[this.pip.id]
            video.volume = this.volume
        }
    }
}
</script>

<style lang="scss" scoped>
.pip-container {
    position: relative;
    video:active {
      cursor: move;
    }
    video {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background-color: black;
    }
    .pip-controls {
        position: absolute;
        top: 5px;
        right: 0;
        height: 45px;
        width: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 10px;

        span,
        .return-to-original-icon,
        .volume-controls {
            background: rgba(0, 0, 0, 0.4);
        }
        span {
            padding: 3px 10px 2px;
            border-radius: 5px;
            font-size: 14px;
        }
        .return-to-original-icon {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: .2s;
            transform: scale(0);
            transform-origin: center;
            cursor: pointer;

            &.show-back-btn {
                transform: scale(1) !important;
            }
        }
        .volume-controls {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            position: absolute;
            left: 10px;
            top: 50px;
            transform: translateY(-50%);
            padding: 5px;
            background: rgba(0, 0, 0, 0.4);
            border-radius: 5px;
            transition: 0.2s;
            transform-origin: center;
            transform: scale(0);

            i {
                font-size: 16px;
                cursor: pointer;
            }

            input[type="range"] {
                -webkit-appearance: none;
                appearance: none;
                width: 5px;
                height: 100px;
                background: transparent;
                writing-mode: vertical-lr;
                transform: rotate(180deg);

                &::-webkit-slider-thumb {
                    -webkit-appearance: none;
                    appearance: none;
                    width: 12px;
                    height: 12px;
                    background: white;
                    border-radius: 50%;
                    cursor: pointer;
                    transform: translateX(-3px);
                }

                &::-webkit-slider-runnable-track {
                    height: 100%;
                    width: 5px;
                    background: gray;
                    border-radius: 2px;
                    margin: auto;
                }
            }

            &.show-volume-controls {
                transform: scale(1) !important;
            }
        }
    }
}
</style>
